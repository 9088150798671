import * as React from 'react';
import { navigate } from '@reach/router';


export default () => {
  React.useEffect(() => {
    navigate('/')?.catch(() => undefined);
  }, []);

  return null;
};
